@use '_colors.scss';

html,
body {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
}

h1 {
    font-size: 1.6em;
}

h2 {
    font-size: 1.4em;
    padding: 10px;
}

h3 {
    font-size: 1.2em;
    color: #00bf8c;
}

h4 {
    font-size: 1.1em;
}

section {
    margin-top: 25px;
    margin-bottom: 25px;

    h1 {
        font-size: 1.2em
    }
}

.wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}

.footer {
    height: 45px;
    margin-top: 15px;
    background-color: #665d51;
    padding-top: 10px;
    color: white;
}

.text-right {
    text-align: right;
}

.text-bold {
    font-weight: bold;
}

.main-container {
    padding-bottom: 30px;
}

.mt-1 {
    margin-top: 10px;
}

.chart-legend li span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

.chart-legend ul {
    list-style-type: none;
}

.rapportTechnique {
    width: 200px;
}

.rapportNb {
    width: 40px;
}

.boTitle {
    color: white;
    background-color: #00bf8c;
}

.eeqAdminTitle {
    color: white;
    background-color: #3BABEC;
}

.eeqTitle {
    margin-bottom: 25px;
}

header h2 small {
    color: inherit;
}

fieldset {
    border: 1px solid #dedede !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

fieldset legend {
    width: inherit;
    /* Or auto */
    padding: 0 10px;
    /* To give a bit of padding on the left and right */
    border-bottom: none;
    text-align: left;
    font-size: 13pt;
    font-weight: bold;
}

.fieldset-sm {
    border: 1px solid #dedede !important;
    padding: 5px !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

.fieldset-sm>.col-sm-6 {
    padding: 2px !important;
}

.fieldset-sm legend {
    width: inherit;
    /* Or auto */
    padding: 0 5px !important;
    /* To give a bit of padding on the left and right */
    border-bottom: none;
    text-align: left;
    font-size: 13pt;
    font-weight: bold;
}

.page-header {
    margin-top: 0px;
}

.container img {
    max-width: 100%;
}

textarea {
    max-width: 100%;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

.staging,
.dev,
.test {
    .navbar {
        background-image: -webkit-linear-gradient(#ffffff, #f0d090);
    }
}

.navbar-brand {
    padding: 5px;
}

.navbar-brand img {
    height: 100%;
}

body.mce-fullscreen nav.navbar {
    display: none;
}

/* ------------------------------ */
/*          T A B L E S           */
/* ------------------------------ */

table {
    border-collapse: collapse;
    font-size: 0.9em;
    /* MD (30/08/19)  table-layout: fixed; */

    &#dpcParticipationList {
        form {
            margin-top: 5px;

            button {
                background: transparent;
                border: none;
            }
        }
    }

    &.confrontations-archived {
        th {
            &.confrontation-questionnaire {
                width: 25%;
            }
        }
    }
}

td {
    /* (MD 30/08/19) vertical-align: middle !important; */
    padding: 1px;
}

th {
    vertical-align: middle !important;
    text-align: left;
}

.tdTop {
    vertical-align: top !important;
}

.tdMiddle {
    vertical-align: middle !important;
}

.form-table-number {
    width: 100px;
}

table .center {
    text-align: center;
}

table .left {
    text-align: left;
}

table .right {
    text-align: right;
}

table .border-top {
    border-top: 1px solid #ddd;
}

table .border-right {
    border-right: 1px solid #ddd;
}

table .border-bottom {
    border-bottom: 1px solid #ddd;
}

table .border-left {
    border-left: 1px solid #ddd;
}

table .number,
table .montant {
    white-space: nowrap;
    text-align: right;
    width: 80px;
}

table .ouinon {
    text-align: center;
    width: 60px;
}

/* table permettant le management des inscriptions par le resp labo */
.table-manage-inscriptions td {
    text-align: center;
}

.table-manage-inscriptions td span {
    font-size: 0.9em;
}

.table-manage-inscriptions thead th {
    text-align: center;
}

.table-manage-inscriptions thead th:first-child,
.table-manage-inscriptions tbody th {
    text-align: left;
    width: 200px;
}

.count {
    min-height: 20px;
    padding: 9px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.pointer {
    cursor: pointer;
}

.factureMontant {
    width: 120px;
    text-align: right;
}

.table-marged {
    margin-top: 15px;
}

.table-sortable th.sortable {
    padding: 0 !important;
}

.table-sortable th {
    white-space: nowrap;
    font-size: 95%;
}

.table-sortable th a {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-position: right;
    background-repeat: no-repeat;
    padding: 8px 30px 6px 8px;
}

th a.sortable {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC');
}

th a.asc {
    border-bottom: 2px solid #dddddd;
    background-color: #dddddd;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==');
}

th a.desc {
    border-bottom: 2px solid #dddddd;
    background-color: #dddddd;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII= ');
}

tr.souscriptions div.checkbox {
    margin-top: 0;
    margin-bottom: 0;
}

tr.user-inactive {
    font-style: italic;
    color: #999;
}

.text-sm {
    font-size: 8pt;
}

.modal-header {
    background-image: linear-gradient(#6d7070, #474949 50%, #3d3f3f);
}

.modal-title {
    color: #fff;
}

.fieldsetLabo1 {
    height: 650px;

    textarea {
        height: 100%;
    }
}

.td-checkbox {
    width: 100px;
    text-align: center;
}

#navigation {}

#form-filter {}

.actions>a {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    margin-right: 5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.actions .email:before {
    content: "\2709";
}

.actions .download:before {
    content: "\e025";
}

.actions .view:before {
    content: "\e105";
}

.actions .print:before {
    content: "\e045";
}

.actions .euro:before {
    content: "\20ac";
}

.actions .edit:before {
    content: "\270f";
}

.actions .refresh:before {
    content: "\e031";
}

.actions .add:before {
    content: "\002b";
}

.actions .details:before {
    content: "\e105";
}

.actions .lock:before {
    content: "\e033";
}

.actions .connect:before {
    content: "\e161";
}

.actions .remove:before {
    content: "\e020";
}

.actions .delete:before {
    content: "\e020";
}

.actions .log:before {
    content: "\e161";
}

.actions .trash:before {
    content: "\e020";
}

.actions .save:before {
    content: "\e173";
}

.actions .cancel:before {
    content: "\e014";
}

button.remove:before {
    content: "\e020";
    font-family: 'Glyphicons Halflings';
    margin-right: 3px;
}

button.save:before {
    font-family: 'Glyphicons Halflings';
    content: "\e173";
    margin-right: 3px;
}

.breakword {
    word-wrap: break-word;
}

.question-groupe.page-header {
    font-size: 1.2em;
    text-transform: uppercase;
}

.table-no-border td {
    border: none !important;
}

.table-no-border th {
    border: none !important;
}

.table-no-border tr {
    border: none !important;
}

.table-no-border {
    border: none !important;
}

.modal-xlg {
    max-width: 1200px;
    width: 80%;
}

.btn-xs {
    margin-bottom: 2px !important;
}

.btn-add:before,
.btn-check:before,
.btn-edit:before,
.btn-export:before,
.btn-save:before,
.btn-delete:before,
.btn-cancel:before,
.btn-email:before,
.btn-download:before,
.btn-view:before,
.btn-education:before,
.btn-print:before {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    margin-right: 3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.btn-add:before {
    content: "\002b";
}

.btn-cancel:before {
    content: "\e014";
}

.btn-check:before {
    content: "\e067";
}

.btn-delete:before {
    content: "\e020";
}

.btn-download:before {
    content: "\e025";
}

.btn-edit:before {
    content: "\270f";
}

.btn-email:before {
    content: "\2709";
}

.btn-export:before {
    content: "\e170";
}

.btn-save:before {
    content: "\e013";
}

.btn-view:before {
    content: "\e105";
}

.btn-education:before {
    content: "\e233";
}

.btn-print:before {
    content: "\e045";
}

.numero {
    width: 70px;
}

.date {
    width: 110px;
}

.table-condensed .date {
    width: 80px;
}

.nom {
    width: 120px;
}

.panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';
    /* essential for enabling glyphicon */
    content: "\e114";
    /* adjust as needed, taken from bootstrap.css */
    float: right;
    /* adjust as needed */
    color: grey;
    /* adjust as needed */
}

.panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e113";
    /* adjust as needed, taken from bootstrap.css */
}

/* Questionnaire */
hr.separator {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.actions-ant {
    width: 110px !important;
    text-align: center;
}

/* Tooltip */
.tooltip {
    font-size: 1.2em !important;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tableResContainer table {
    font-size: 8pt !important;
    word-wrap: break-word;
    display: block;
    overflow-x: auto;
}

.tableResContainer table th {
    padding: 2px !important;
    border: 1px solid #efefef;
}

.tableResContainer table td {
    padding: 2px !important;
    border: 1px solid #efefef;
}

.bar {
    height: 20px;
    background: #00bf8c;
}

/* ----------------------------------------------*/
/*          Q U E S T I O N N A I R E S  */
/* ---------------------------------------------- */
fieldset.qualitatifs legend {
    margin-bottom: 5px;
    font-size: 11pt;
}

div.menu-checkboxes {
    max-height: 300px;
    overflow: auto;
}

div.has-error,
fieldset.has-error {
    background-color: #f2dede;
}

.has-error .help-block {
    font-weight: bold;
}

.question.radiotext .valeurs .radio,
.question.number .valeurs .radio {
    float: left;
}

.question.radiotext label {
    top: 15px;
}

.question.number input {
    float: right;
    width: 50%;
    min-width: 10em;
    text-align: right;
}

.resultats-attendus fieldset label {
    margin-left: 0 !important;
}

.question-tpl {
    margin-bottom: 25px;
}

.question.radio {
    label.question {
        font-weight: bold;
        padding-left: 0;
    }

    div.radio {
        display: flex;
        flex-wrap: wrap;
    }

    label.radio-inline {
        margin-left: 10px;
        flex: 0 1 250px;
    }

    .menu-gen-oui-non label.radio-inline,
    .menu-gen-absence-presence label.radio-inline,
    .menu-gen-sensibilite label.radio-inline {
        flex: 0 1 120px;
    }

    .menu-syp-reponses label.radio-inline {
        flex: 0 1 100%;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #efefef;
    }

    .menu-syp-reponses label.radio-inline:hover {
        background-color: #efefef;
    }

}

/*  M Y C O L O G I E  */
.antifongigramme .btn-group .active {
    background-color: #3CB521; // (idem success)
    border-color: #3CB521;
}

/*  B A C T E R I O L O G I E  */
.antibiogramme .btn-group .active {
    background-color: #3CB521; // (idem success)
    border-color: #3CB521;
}

.antibiogramme a.delete {
    color: red;
}

.cmi .operateur {
    width: 75px;
}

.cmi .operateur select {
    width: 100%;
}

.cmi .valeur {
    width: 100px;
}

.cmi .valeur select {
    width: 100%;
    text-align: right;
}

.formule_pourcentage,
.hemato_val_td {
    width: 100px;
    text-align: center;
}

.formule_pourcentage td th {
    vertical-align: middle !important;
}

.formule_pourcentage input {
    width: 60px;
    display: inline-block;
    height: 2em;
    padding: 0 5px;
    text-align: right;
}

#formule_somme,
#formule_somme_visu {
    text-align: right;
}

.subtable_questionnaire_certif {
    width: 100px !important;
}

.subtable_questionnaire_lib {}

.subtable_questionnaire_actions {
    width: 80px !important;
}

/* Gestionnaire d'images */
/* --------------------- */
.images-list li table {
    width: 95%;
}

.input-minimalist {
    background: none;
    margin: 0px;
    color: inherit;
    border: none;
    padding: 0px;
    max-width: 100%;
}

textarea.updatable_image {
    height: 4em;
}

.onglet-images {
    padding-bottom: 20px;
}

.table_myelo {
    font-size: 0.85em !important;
}

.table_myelo td {
    padding: 2px !important;
}

.table_myelo th {
    padding: 2px !important;
}

.table_myelo_input_btn {
    width: 30px;
}

.table_myelo_input {
    width: 110px;
    text-align: center;
}

.table_myelo_input_input {
    width: 50px;
    text-align: center;
}

.table_myelo_lib {}

.table_myelo_resultats_brut {
    text-align: right;
    width: 125px;
}

.table_myelo_resultats_percent {
    text-align: right;
}

/*
    myelo_ligne_[code_menu_item]
    => les classes placées sur les <tr> des tables myelogramme
    ( remplacer [code_menu_item] par le code cible de l'item du menu MYE-CELLULES )
*/

.accueil-eeq h1 {
    font-size: 22pt;
    font-weight: bold;
}

.table-results {
    margin-top: 10px;
}

.note-A {
    background-color: $brand-expected !important;
}

.note-B {
    background-color: $brand-acceptable !important;
}

.note-C {
    background-color: $brand-analyse !important;
}

.note-D {
    background-color: $brand-wrong !important;
}

.notation-mye .note-A {
    background-color: inherit;
}

.notation-mye .note-B {
    background-color: inherit;
}

.notation-mye .note-C {
    background-color: inherit;
}

.notation-mye .note-D {
    background-color: inherit;
}

.notation-cyt .note-A {
    background-color: inherit;
}

.notation-cyt .note-B {
    background-color: inherit;
}

.notation-cyt .note-C {
    background-color: inherit;
}

.notation-cyt .note-D {
    background-color: inherit;
}

.notation-note {
    width: 5%;
}

.notation-question {}

.notation-reponse {
    width: 18%;
}

.notation-acceptable {
    width: 18%;
}

.notation-attendu {
    width: 18%;
}

.notation-analyse {
    width: 18%;
}

.notation-min {
    width: 10%;
}

.notation-valeur {
    width: 10%;
}

.notation-max {
    width: 10%;
}

.radio-buttons input[type="radio"],
.radio-buttons input[type="radio"]+.fa {
    opacity: 0;
}

.radio-buttons input[type="radio"]+i+span {
    color: inherit;
}

.radio-buttons input[type="radio"]:hover+.fa,
.radio-buttons input[type="radio"]:checked+.fa,
.radio-buttons input[type="radio"]:hover+i+span,
.radio-buttons input[type="radio"]:checked+i+span {
    opacity: 1;
    color: white;
}

tr.souscription-inactive {
    font-style: italic !important;
    color: #999 !important;
}

tr.souscription-active {}

.notations-list th.commentaire {
    max-width: 15%;
}

.notation-cyt .table-resultats .libelle {
    width: 55%;
}

.table-resultats .number {
    width: 10%;
}

.table-resultats .note {
    width: 5%;
    text-align: center;
}

.notations-list th.actions {
    width: 250px;
}

.panel-inverse>.panel-heading {
    background-color: #474949;
    color: #fafafa;
}

input[type="number"] {
    text-align: right;
}

.en-tete-sousfamille {
    letter-spacing: 1px;
    background-color: #f9f9f9;
}

.eeq-checked {
    background-color: #e9fce9;
}

/* DPC */
.dpc {
    position: relative;

    .reponses .checkbox {
        padding: 3px;
        padding-right: 20px;
    }

    .reponse_attendue {
        font-weight: bold;
    }

    .reponse_participant_ok {
        background-color: initial;
        color: initial;
    }

    .reponse_participant_ok:after {
        font-family: FontAwesome;
        content: "\f00c";
        color: green;
        position: absolute;
        top: 2px;
        right: 2px;
    }

    .reponse_participant_nok {
        background-color: #fff0f0;
        color: red !important;

        .reponse_attendue {
            font-style: italic;
        }

        .reponse_non_attendue {
            text-decoration: line-through;
        }
    }

    table {
        th {
            padding: 5px;
        }

        td {
            padding: 5px;
        }
    }

    .question {
        margin-left: 25px;
        margin-bottom: 25px;
    }

    .reponses {
        margin-left: 25px;
    }

    .comment {
        border: 1px solid #ccc;
        padding: 5px;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .questionnaires {
        display: flex;
        width: 100%;
        margin: auto;

        h4 {
            color: $primary;
        }

        .well {
            flex: 1;
            /* additionally, equal width */

            padding: 1em;
            border: 2px solid #777;
            margin-right: 2px;

            a {
                margin-top: 10px;
            }
        }
    }
}

.table-certificats-dpc .validation {
    width: 50px;
    text-align: center;
}

.table-certificats-dpc .certificat {
    width: 250px;
    text-align: center;
}

.reclamation-container .fichiers {
    margin-top: 35px;
}

.table-synthese-confrontations {
    margin-bottom: 50px;

    .username {
        width: 25%;
    }

    .confrontation-libelle {
        text-align: center;
    }
}

@media print {
    a.btn-warning {
        display: none;
    }
}

.nav-resp-labo {
    margin-bottom: 15px;
}

tr.participation {

    &.pdf-exists {}

    &.pdf-non-exists {}

    &.validated {

        font-style: normal;

        /* Bouton case validated */
        .cri-pdf-btn {

            /* Bouton exists & validated*/
            &.exists {
                color: $btn-primary-color;
                background-color: $btn-primary-bg;
                border-color: $btn-primary-border;
            }

            /* Bouton NON exists & validated*/
            &.non-exists {
                color: $btn-danger-color;
                background-color: $btn-danger-bg;
                border-color: $btn-danger-border;
            }
        }
    }

    &.non-validated {

        font-style: italic;
        color: $gray-light;

        /* Bouton case non validated */
        .cri-pdf-btn {

            /* Bouton exists & NON validated */
            &.exists {
                color: $btn-primary-color;
                background-color: $btn-primary-bg;
                border-color: $btn-primary-border;
            }

            /* Bouton NON exists & NON validated*/
            &.non-exists {
                color: $btn-warning-color;
                background-color: $btn-warning-bg;
                border-color: $btn-warning-border;
            }
        }
    }
}


// ======== Boutons correction (cf. bootswatch) =============================================

.radio-buttons {
    .btn {

        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
        width: 45px;
        border: 1px solid #ccc;

        i {
            display: none;
        }

        &:hover i,
        & input:checked+.fa {
            display: inline-block;
        }

        &-expected {

            // @include btn-shadow($btn-expected-bg);
            &:hover,
            &:active,
            &.active {
                color: $btn-expected-color;
                @include btn-shadow(darken($btn-expected-bg, 4%));
            }
        }

        &-acceptable {

            // @include btn-shadow($btn-acceptable-bg);
            &:hover,
            &:active,
            &.active {
                color: $btn-acceptable-color;
                @include btn-shadow(darken($btn-acceptable-bg, 4%));
            }
        }

        &-analyse {

            // @include btn-shadow($btn-analyse-bg);
            &:hover,
            &:active,
            &.active {
                color: $btn-analyse-color;
                @include btn-shadow(darken($btn-analyse-bg, 4%));
            }
        }

        &-wrong {

            // @include btn-shadow($btn-wrong-bg);
            &:hover,
            &:active,
            &.active {
                color: $btn-wrong-color;
                @include btn-shadow(darken($btn-wrong-bg, 4%));
            }
        }

        &-unrated {

            // @include btn-shadow($btn-unrated-bg);
            &:hover,
            &:active,
            &.active {
                color: $btn-unrated-color;
                @include btn-shadow(darken($btn-unrated-bg, 4%));
            }
        }

        &:active,
        &.active {
            // background-image: none;
            @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
        }
    }
}

.corrige-cyt,
.corrige-mye {
    p.avertissement {
        display: none;
    }
}


/* Bandeau RGPD cookie */
.cc-revoke {
    display: none !important;
}

.alert-dark {
    background-color: rgba(78, 73, 73, 0.301);
}

.form-actions {
    position: relative;
}

.to_recompute {
    border-color: #D47500;
}
