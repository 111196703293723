@import "bootstrap-custom/variables";


// ----- STACIL ------
// -------------------

$brand-expected:         rgba(0, 159, 122, 0.4) !default;
// $brand-expected:         rgba(0, 191, 140, 0.4) !default;
$brand-acceptable:       rgba(127, 240, 21, 0.4) !default;
// $brand-acceptable:       rgba(127, 240, 21, 0.8) !default;
$brand-analyse:          rgba(245, 123, 42, 0.4) !default;
// $brand-analyse:          rgba(242, 143, 26, 0.8) !default;
$brand-wrong:            rgba(214, 50, 50, 0.4) !default;
// $brand-wrong:            rgba(229, 51, 42, 0.8) !default;
$brand-unrated:          $btn-default-bg !default;

$btn-expected-color:     white;
$btn-expected-bg:        rgba(0, 159, 122, 0.8) !default;
$btn-expected-border:    $btn-expected-bg !default;

$btn-acceptable-color:   white;
$btn-acceptable-bg:      rgba(127, 240, 21, 0.8) !default;
$btn-acceptable-border:  $btn-acceptable-bg !default;

$btn-analyse-color:      white;
$btn-analyse-bg:         rgba(245, 123, 42, 0.8) !default;
$btn-analyse-border:     $btn-analyse-bg !default;

$btn-wrong-color:        white;
$btn-wrong-bg:           rgba(214, 50, 50, 0.8) !default;
$btn-wrong-border:       $btn-wrong-bg !default;

$btn-unrated-color:      white;
$btn-unrated-bg:         $brand-unrated !default;
$btn-unrated-border:     $btn-unrated-bg !default;

@mixin btn-shadow($color){
  @include gradient-vertical-three-colors(lighten($color, 15%), $color, 50%, darken($color, 4%));
  filter: none;
  border: 1px solid darken($color, 10%);
}
